@use '@angular/material' as mat;
@import "@angular/material/theming";

$voog-palette: (
  50: #e1e9f4,
  100: #b5c8e4,
  200: #83a3d3,
  300: #517ec1,
  400: #2c63b3,
  500: #0747a6,
  600: #06409e,
  700: #053795,
  800: #042f8b,
  900: #02207b,
  A100: #ffd965,
  A200: #ffd247,
  A400: #ffca28,
  A700: #ffa000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// // Background palette for light themes.
// mat.$light-theme-background-palette: (
//   status-bar: map_get(mat.$grey-palette, 300),
//   app-bar: #f4f5f7,
//   background: #f4f6fc,
//   hover: rgba(black, 0.04),
//   // TODO(kara): check style with Material Design UX
//   card: white,
//   dialog: white,
//   disabled-button: rgba(black, 0.12),
//   raised-button: white,
//   focused-button: $dark-focused,
//   selected-button: map_get(mat.$grey-palette, 300),
//   selected-disabled-button: map_get(mat.$grey-palette, 400),
//   disabled-button-toggle: map_get(mat.$grey-palette, 200),
//   unselected-chip: map_get(mat.$grey-palette, 300),
//   disabled-list-option: map_get(mat.$grey-palette, 200),
// );

// // Background palette for dark themes.
// mat.$dark-theme-background-palette: (
//   status-bar: black,
//   app-bar: map_get(mat.$grey-palette, 900),
//   background: #303030,
//   hover: rgba(white, 0.04),
//   // TODO(kara): check style with Material Design UX
//   card: map_get(mat.$grey-palette, 800),
//   dialog: map_get(mat.$grey-palette, 800),
//   disabled-button: rgba(white, 0.12),
//   raised-button: map_get(mat.$grey-palette, 800),
//   focused-button: $light-focused,
//   selected-button: map_get(mat.$grey-palette, 900),
//   selected-disabled-button: map_get(mat.$grey-palette, 800),
//   disabled-button-toggle: black,
//   unselected-chip: map_get(mat.$grey-palette, 700),
//   disabled-list-option: rgba(white, 0.04),
// );

// // Foreground palette for light themes.
// mat.$light-theme-foreground-palette: (
//   base: black,
//   divider: $dark-dividers,
//   dividers: $dark-dividers,
//   disabled: $dark-disabled-text,
//   disabled-button: rgba(black, 0.26),
//   disabled-text: $dark-disabled-text,
//   hint-text: $dark-disabled-text,
//   secondary-text: $dark-secondary-text,
//   icon: rgba(black, 0.54),
//   icons: rgba(black, 0.54),
//   text: #29374d,
//   slider-min: rgba(black, 0.87),
//   slider-off: rgba(black, 0.26),
//   slider-off-active: rgba(black, 0.38),
// );

// // Foreground palette for dark themes.
// mat.$dark-theme-foreground-palette: (
//   base: white,
//   divider: $light-dividers,
//   dividers: $light-dividers,
//   disabled: $light-disabled-text,
//   disabled-button: rgba(white, 0.3),
//   disabled-text: $light-disabled-text,
//   hint-text: $light-disabled-text,
//   secondary-text: $light-secondary-text,
//   icon: white,
//   icons: white,
//   text: white,
//   slider-min: white,
//   slider-off: rgba(white, 0.3),
//   slider-off-active: rgba(white, 0.3),
// );

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.



$custom-typography: mat.define-legacy-typography-config(
  $font-family: "Lato, sans-serif",
 
);
// $headline: mat.define-typography-level(24px, 32px, 700),
// $title: mat.define-typography-level(20px, 32px, 700),
// $button: mat.define-typography-level(14px, 14px, 700),
// $input: mat.define-typography-level(inherit, 1.125, 700),

$app-light-theme-primary: mat.define-palette($voog-palette);
$app-light-theme-accent: mat.define-palette($voog-palette, A200, A100, A400);
$app-light-theme-warn: mat.define-palette(mat.$red-palette);
$app-light-theme: mat.define-light-theme((
 color: (
   primary: $app-light-theme-primary,
   accent: $app-light-theme-accent,
   warn: $app-light-theme-warn,
 ),
 typography: $custom-typography,
 density: 0,
));

// Define an alternate dark theme.
$app-dark-theme-primary: mat.define-palette(mat.$blue-grey-palette);
$app-dark-theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400); // mat.define-palette(mat.$amber-palette, A200, A100, A400);
$app-dark-theme-warn: mat.define-palette(mat.$red-palette); // mat.define-palette(mat.$deep-orange-palette);
$app-dark-theme: mat.define-dark-theme((
 color: (
   primary: $app-dark-theme-primary,
   accent: $app-dark-theme-accent,
   warn: $app-dark-theme-warn,
 ),
 typography: $custom-typography,
 density: 0,
));

// custom variables
$background-light: map_get($app-light-theme, background);
$background-dark: map_get($app-dark-theme, background);
$foreground-light: map_get($app-light-theme, foreground);
$foreground-dark: map_get($app-dark-theme, foreground);

$toolbar-height: 68px;
$top-toolbar-height: 0;
$sidebar-start-width: 68px;
$content-max-width: 1000px;
$small-content-max-width: 640px;
$bg-detail-page-top-padding: 24px;

$mat-card-default-padding: 16px; // 24px;
$mat-card-mobile-padding: 16px; //  24px 16px;

$default-padding: 16px;
$mobile-padding: 16px;
