.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.bg-white {
  background-color: #fff;
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2em;
  line-height: 1em;
}

.no-margin {
  margin: 0;
}

.margin-all {
  margin: $mat-card-default-padding;
  @media (max-width: 599px) {
    margin: $mat-card-mobile-padding;
  }
}

.margin-top {
  margin-top: 24px;
}

.margin-left {
  margin-left: 24px;
}

.margin-right {
  margin-right: 24px;
}

.margin-bottom {
  margin-bottom: 24px;
}

.margin-edge {
  margin-left: -24px;
  margin-right: -24px;
}

.margin-edge-small {
  margin-left: -16px;
  margin-right: -16px;
}

.opacity-fade {
  opacity: 0.6;
  cursor: default;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

div.margin-split {
  height: 0.01px;
}
p.margin-split {
  padding-bottom: 0.01px;
}

ul.unstyle,
ol.unstyle {
  list-style-type: none;
}

.custom-scrollstop {
  position: fixed;
  width: 100%;
  overflow-y: hidden;
}
