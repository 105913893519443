@use '@angular/material' as mat;
@import "./_variables.scss";
html {
  height: 100%; // overflow: hidden;
  position: relative;
  padding: 0;
  margin: 0;
}

@mixin app-body-theme($theme) {
  $background: map_get($theme, background);
  $foreground: map_get($theme, foreground);
  background-color: mat.get-color-from-palette($background, background);
  color: mat.get-color-from-palette($foreground, text);
}

body {
  //padding-top: 56px;// + 60.6;
  // overflow: hidden;
  @include app-body-theme($app-light-theme);
  &.app-dark-theme,
  .app-dark-theme & {
    @include app-body-theme($app-dark-theme);
  }
  height: 100%;
  // min-width: 600px; // min-height: calc(100% - 64px);
  min-height: 100%;
  box-sizing: border-box;
  padding: 0;
  &.logged-in {
    padding-top: $top-toolbar-height;
    padding-left: $sidebar-start-width;
    .fixed-fix {
      left: $sidebar-start-width;
    }

    @media (max-width: 800px) {
      padding-top: $top-toolbar-height + $sidebar-start-width;
      padding-left: 0;
      .fixed-fix {
        left: 0;
        top: $sidebar-start-width;
      }
    }
  }
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  display: flex;
  align-items: stretch;
  > app-root {
    flex-grow: 1;
  }
}

label {
  margin-bottom: 0rem;
}

ul,
ol {
  padding: 0;
}

p {
  word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: normal;
  font-weight: lighter;
}

.color-danger {
  color: red;
}

.clickable {
  cursor: pointer;
}

.visual-table-container {
  > h3 {
    margin: 16px 24px 6px 24px;
  }
  border-radius: 2px;
}

table.visual-table {
  border-spacing: 0;
  // font-family: Roboto, Helvetica Neue, sans-serif;
  width: 100%;
  > thead {
    > th {
      padding: 17px 0 17px 24px;
      text-align: left;
      overflow: hidden;
      word-wrap: break-word;
      color: mat.get-color-from-palette($foreground-light, text);
      border-bottom: 1px solid mat.get-color-from-palette($foreground-light, divider);
      .app-dark-theme & {
        color: mat.get-color-from-palette($foreground-dark, text);
        border-bottom: 1px solid mat.get-color-from-palette($foreground-dark, divider);
      }
      font-size: 12px;
      font-weight: 500;
      &:last-child {
        padding-right: 24px;
      }
    }
  }
  > tbody {
    > tr {
      > td {
        padding: 16px 0 16px 24px;
        overflow: hidden;
        word-break: break-word;
        white-space: normal;
        font-size: 14px;
        color: mat.get-color-from-palette($foreground-light, text);
        border-bottom: 1px solid mat.get-color-from-palette($foreground-light, divider);
        .app-dark-theme & {
          color: mat.get-color-from-palette($foreground-dark, text);
          border-bottom: 1px solid mat.get-color-from-palette($foreground-dark, divider);
        }
        &:last-child {
          padding-right: 24px;
        }
        &:last-child.actions,
        &:last-child.action {
          width: 1px;
          white-space: nowrap;
        }
        &.actions,
        &.action {
          .mat-icon-button {
            margin-top: -12px;
            margin-bottom: -12px;
            &:first-child {
              margin-left: -12px;
            }
            &:last-child {
              margin-right: -12px;
            }
          }
        }
      }
      &:not(:hover) {
        > td.actions {
          .mat-icon-button {
            // display: none;
            opacity: 0;
          }
        }
      }
    }
  }
  &.clickable {
    > tbody {
      > tr {
        &:hover {
          cursor: pointer;
          background-color: mat.get-color-from-palette($background-light, hover);
          .app-dark-theme & {
            background-color: mat.get-color-from-palette($background-dark, hover);
          }
        }
      }
    }
  }
}

.flex-row {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  &.with-space {
    > * {
      width: 100%;
      margin-left: 12px;
      margin-right: 12px;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.flex-row-spacebetween-center {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex-row-wrap {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.flex-item {
  flex: 1 1 0.000000001px;
  box-sizing: border-box;
}

.module-content-container {
  width: 100%;
  height: 100%;
  > .content {
    width: 100%;
    height: 100%;
  }
}

.hoverIconChange {
  .hoverIconChangeHover {
    display: none;
  }
  &:hover {
    .hoverIconChangeIdle {
      display: none;
    }
    .hoverIconChangeHover {
      display: inline-block;
    }
  }
}
