@use '@angular/material' as mat;
.cdk-overlay-container {
  z-index: 1400; // to get it over navbar
}

.cdk-global-overlay-wrapper.large-dialog {
  align-items: flex-start !important;
  overflow-y: scroll;
  padding: 60px 20px;
  pointer-events: auto;
  .cdk-overlay-backdrop {
    pointer-events: auto;
  }
}

.cdk-global-overlay-wrapper.full-dialog {
  align-items: flex-start !important;
  overflow-y: scroll;
  padding: 60px 20px;
  pointer-events: auto;
  > .cdk-overlay-pane {
    width: 100%;
    > .mat-dialog-container {
      max-width: none;
      min-height: calc(100vh - 120px);
    }
  }
  .cdk-overlay-backdrop {
    pointer-events: auto;
  }
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-sidenav-container {
  min-height: 100%; // height: 100%;
}

mat-form-field {
  input[type="time"] {
    margin-top: -0.1625em;
    margin-bottom: -0.2625em;
  }
  .mat-form-field-label {
    color: inherit;
  }
  &.mat-form-field-appearance-outline .mat-form-field-outline {
    color: inherit;
  }
}

.mat-card-header {
  .mat-card-header-text {
    margin-left: 0;
  }
}

.mat-card-content {
  .mat-nav-list {
    margin-left: -16px;
    margin-right: -16px;
    > .mat-list-item {
      .mat-list-item-content {
        padding: 0 24px;
      }
    }
  }
  .visual-table-container {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.mat-dialog-container {
  .mat-dialog-actions {
    padding-top: 24px;
  }
}

.mat-snack-bar-container {
  &.error {
    background-color: darkred;
  }
}

.upload-summary-dialog-container {
  margin-left: auto;
  margin-top: auto;
  margin-right: 24px;
  margin-bottom: 24px;
  .mat-dialog-container {
    background-color: transparent;
  }
}

.mat-button,
.mat-raised-button,
.mat-flat-button,
.mat-stroked-button,
.mat-icon-button,
.mat-fab,
.mat-mini-fab {
  text-transform: uppercase; // According to the Material design spec button text has to be capitalized
}

.mat-button,
.mat-raised-button {
  .mat-icon {
    transform: translateX(-30%) translateY(-10%); // position icon if used inside a button
  }
}
.cdk-overlay-pane.right-side-dialog {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  height: 100vh;
  .mat-dialog-container {
    display: flex !important;
    flex-direction: column;
    > * {
      display: flex;
      flex-direction: column;
      height: 100%;
      .mat-dialog-content {
        flex-grow: 1;
        max-height: inherit !important;
      }
      .mat-dialog-actions {
        display: block !important;
        flex-grow: inherit;
        flex-shrink: inherit;
      }
    }
  }
}

.mat-menu-panel.app-dark-theme {
  $background: map_get($app-dark-theme, background);
  background: mat.get-color-from-palette($background, dialog);
}

.mat-select.simple,
.mat-input-element.simple {
  padding: 4px !important;
  border-radius: 2px !important;
  border: 1px solid lightgray !important;
  background-color: #fff !important;
  .app-dark-theme & {
    border: 1px solid #212121 !important;
    background-color: #303030 !important;
  }
  margin-top: 0 !important;
  box-sizing: border-box;
}

// .mat-form-field-appearance-outline .mat-form-field-outline {
//     color: rgba(0, 0, 0, 1);
//     .app-dark-theme & {
//         color: rgba(255, 255, 255, 0.3);
//     }
// }
.mat-form-field {
  .mat-form-field-infix {
    width: auto;
  }
}

.mat-form-field.plane {
  font-size: 14px;
  margin-right: 16px;
  .mat-form-field-infix {
    width: auto;
  }
  .mat-select {
    .mat-select-value {
      max-width: none;
    }
  }
  .mat-select-arrow-wrapper {
    padding-left: 4px;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-prefix {
    font-weight: normal;
  }
  .mat-form-field-suffix {
    transform: translateY(22%);
  }
  &.mat-form-field-appearance-standard .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper {
    transform: translateY(0);
  }
}

table.mat-table {
  width: 100%;
  td.mat-cell,
  td.mat-footer-cell,
  th.mat-header-cell {
    padding-right: 12px;
  }
}

.mat-nav-list.simple-list {
  margin: 24px;
  > .mat-list-item {
    font-size: 20px;
    &:not(:hover) {
      .mat-icon-button {
        // display: none;
        opacity: 0;
      }
    }
  }
}

.mat-card-content > :first-child,
.mat-card {
  > div.custom-card-title:first-child {
    h4 {
      margin: 0;
    }
    margin: -24px -24px 16px -24px;
    padding: 20px 24px;
    background-color: #eeeeee;
  }
}

.sidenav-menu {
  .mat-list-item.active,
  .mat-menu-item.active {
    box-shadow: inset 3px 0px 0px 0 mat.get-color-from-palette($app-light-theme-accent);
    .app-dark-theme & {
      box-shadow: inset 3px 0px 0px 0 mat.get-color-from-palette($app-dark-theme-accent);
    }
  }
}

.mat-table {
  .mat-column-actions,
  .mat-column-action,
  .mat-column-connection {
    flex: 0 1 50px;
    width: 50px;
    text-align: right;
    white-space: nowrap;
  }
  .mat-column-select {
    flex: 0 1 24px;
    width: 24px;
    text-align: left;
    white-space: nowrap;
  }
  .mat-column-actions {
    .mat-form-field{
      width: 51px;
    }
  }
  .mat-row {
    &:not(:hover) {
      .mat-column-actions {
        .mat-icon-button {
          // display: none;
          opacity: 0;
        }
      }
    }
  }
  &.clickable {
    .mat-row {
      cursor: pointer;
    }
    .stopclick {
      cursor: initial;
    }
  }
}

.card-content-top {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}
